@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import '/src/styles/text-helpers';

.content {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    padding-top: @magritte-core-space-2-x;

    .screen-gt-s({
        min-height: 314px;
    });
}

.breadcrumbs {
    margin-bottom: @magritte-core-space-3-x;
}

.title {
    .magritte-typography-title-5-semibold();

    margin-bottom: @magritte-core-space-3-x;
    color: @magritte-color-text-primary;

    .screen-gt-s({
        .multiline_ellipsis(2);
    });
}

.provider {
    margin-bottom: @magritte-core-space-1-x;
}

.top-content {
    padding-left: @magritte-core-space-2-x;
    padding-right: @magritte-core-space-2-x;
}

.bottom-content {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    margin-top: 40px;
    justify-content: flex-end;
}

.promocode {
    margin-bottom: @magritte-core-space-3-x;
    padding-left: @magritte-core-space-2-x;
    padding-right: @magritte-core-space-2-x;
}

.price {
    margin-bottom: @magritte-core-space-4-x;
    padding-left: @magritte-core-space-2-x;
    padding-right: @magritte-core-space-2-x;
}

.tag-course-developed {
    margin-bottom: @magritte-core-space-3-x;
    padding-left: @magritte-core-space-2-x;
    padding-right: @magritte-core-space-2-x;
}
