@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import 'styles/common';

.fields {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-3-x;

    .screen-gt-s({
        flex-direction: row;
    });
}

.field {
    flex: 1 1 auto;
}

.aggreement-label {
    margin-bottom: 30px;

    .screen-gt-s({
        margin-bottom: 0;
    });
}

.form {
    display: flex;
    flex-direction: column;
    gap: @magritte-core-space-6-x;
}
